<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('CLIENTS.TITLE_EDIT') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'companyClientsIndex' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" md="8" offset-md="2">
            <b-form-group :label="`${$t('FORM.FIRST_NAME')}`" label-for="input-1" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-1"
                v-model="$v.form.firstName.$model"
                :state="validateState('firstName')"
                :placeholder="$t('PLACEHOLDER.FIRST_NAME')"
                trim
                @input="clearServerError('firstName')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback" :class="{'d-block': validateState('firstName') === false}">
                <template v-if="serverErrors.firstName">{{ serverErrors.firstName[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.FIRST_NAME') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="`${$t('FORM.LAST_NAME')}`" label-for="input-2" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-2"
                v-model="$v.form.lastName.$model"
                :state="validateState('lastName')"
                :placeholder="$t('PLACEHOLDER.LAST_NAME')"
                trim
                @input="clearServerError('lastName')"
                aria-describedby="input-2-feedback"
              />
              <b-form-invalid-feedback id="input-2-feedback" :class="{'d-block': validateState('lastName') === false}">
                <template v-if="serverErrors.lastName">{{ serverErrors.lastName[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.LAST_NAME') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="`${$t('FORM.PHONE')}`" label-for="input-3" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-3"
                v-model="$v.form.phone.$model"
                :state="validateState('phone')"
                :placeholder="$t('PLACEHOLDER.PHONE')"
                trim
                @input="clearServerError('phone')"
                aria-describedby="input-2-feedback"
              />
              <b-form-invalid-feedback id="input-2-feedback" :class="{'d-block': validateState('phone') === false}">
                <template v-if="serverErrors.phone">{{ serverErrors.phone[0] }}</template>
                <template v-else-if="!$v.form.phone.alphaPhone">{{ $t('VALIDATION.INVALID', { name: $t('FORM.PHONE') }) }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.PHONE') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <!--              <b-form-group :label="`${$t('FORM.PASSWORD')}:`" label-for="input-4">-->
            <!--                <InputForm-->
            <!--                  id="input-4"-->
            <!--                  v-model="$v.form.plainPassword.$model"-->
            <!--                  :state="validateState('plainPassword')"-->
            <!--                  :placeholder="$t('PLACEHOLDER.PASSWORD')"-->
            <!--                  trim-->
            <!--                  @input="clearServerError('plainPassword')"-->
            <!--                  aria-describedby="input-1-feedback"/>-->
            <!--                <b-form-invalid-feedback id="input-1-feedback" :class="{'d-block': validateState('plainPassword') === false}">-->
            <!--                  <template v-if="serverErrors.plainPassword">{{ serverErrors.plainPassword[0] }}</template>-->
            <!--                  <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.PASSWORD') }) }}</template>-->
            <!--                </b-form-invalid-feedback>-->
            <!--              </b-form-group>-->
          </b-col>
          <b-col cols="12">
            <div class="text-right">
              <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
                <b-spinner v-if="loading" small variant="light" />
                {{ $t('FORM.SUBMIT') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </template>
</KTCard>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { validationMixin } from 'vuelidate'
import { helpers, required } from 'vuelidate/lib/validators'
import Repo from '@/core/repository/company/clientsRepository'
import { TIMEOUT_REDIRECT } from '@/constants/base'
import KTCard from '../../../../content/Card'
import InputForm from '../../../../../components/forms-items/input'
import serverVuelidate from '../../../../../mixins/serverVuelidate'
import { TEL } from '../../../../../helpers/validation'

export default {
  name: 'CompanyClientsEdit',
  components: {
    InputForm,
    KTCard, 
  },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      loading: false,
      form: {
        phone: '',
        firstName: '',
        lastName: '',
      },
      validations: {
        form: {
          phone: {
            required,
            alphaPhone: helpers.regex('alpha', TEL), 
          },
          firstName: { },
          lastName: { },
        },
      },
    }
  },
  mounted() {
    this.fetchData()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('CLIENTS.TITLE_EDIT') }])
  },
  methods: {
    fetchData() {
      Repo.get(this.$route.params.id).then(({ data }) => {
        this.setItem(data.payload)
      })
    },
    setItem(item) {
      Object.keys(this.form).forEach((propName) => {
        if (
          this.form.hasOwnProperty(propName)
            && item.hasOwnProperty(propName)
        ) {
          if (item[propName] !== null && item[propName] !== undefined) {
            this.form[propName] = item[propName]
          }
        }
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    async onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true

      const obj = { ...this.form }

      Repo.patch(this.$route.params.id, obj).then(() => {
        this.$bvToast.toast(this.$t('TOAST.EDITED'), {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
          solid: true,
        })
        setTimeout(() => this.$router.push({ name: 'companyClientsIndex' }), TIMEOUT_REDIRECT)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.seServerErrors(err.response.data)
      })
    },
  },
}
</script>

<style scoped>

</style>
